var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translate("Orders"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"sort-by.sync":"order_status","sort-desc.sync":"true","search":_vm.search,"items-per-page":20,"headers":_vm.headers,"items":_vm.getProducts},scopedSlots:_vm._u([{key:"item.order_status_formatted",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium v-chip v-size--small",class:item.color},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(item.order_status_formatted)+" ")])])]}},{key:"item.order_created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.order_created_at)))]),_vm._v("  "),_c('span',{staticClass:"font-weight-medium v-chip v-size--small primary"},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.timeAgo(item.order_created_at))+" ")])])]}},{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.deadline)))]),_vm._v("  "),_c('span',{staticClass:"font-weight-medium v-chip v-size--small primary"},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.timeAgo(item.deadline))+" ")])])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"width":"60%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"color":"red lighten-2","dark":""}},'a',attrs,false),on),[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("#"+_vm._s(item.id))])])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(_vm._s(_vm.translate("Order"))+" #"+_vm._s(item.id))]),_c('v-card-text',[_c('OrderInfoChief',{attrs:{"currency":_vm.currency,"order":item},on:{"setNewData":_vm.setNewData}})],1),_c('v-divider')],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return undefined}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }