<template>
  <div>
    <v-card>
      <v-card-title>
        {{ translate("Orders") }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        sort-by.sync="order_status"
        sort-desc.sync="true"
        :search="search"
        :items-per-page="20"
        :headers="headers"
        :items="getProducts"
        class="elevation-1"
      >
        <template v-slot:item.order_status_formatted="{ item }">
          <span class="font-weight-medium v-chip v-size--small" :class="item.color"
            ><span class="v-chip__content">
              {{ item.order_status_formatted }}
            </span></span
          >
        </template>
        <template v-slot:item.order_created_at="{ item }">
          <span>{{ formatDate(item.order_created_at) }}</span
          >&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"
            ><span class="v-chip__content">
              {{ timeAgo(item.order_created_at) }}
            </span></span
          >
        </template>
        <template v-slot:item.deadline="{ item }">
          <span>{{ formatDate(item.deadline) }}</span
          >&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"
            ><span class="v-chip__content"> {{ timeAgo(item.deadline) }} </span></span
          >
        </template>
        <template v-slot:item.id="{ item }">
          <v-dialog width="60%">
            <template v-slot:activator="{ on, attrs }">
              <a color="red lighten-2" dark v-bind="attrs" v-on="on">
                <span style="font-weight: bold">#{{ item.id }}</span>
              </a>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2"
                >{{ translate("Order") }} #{{ item.id }}</v-card-title
              >
              <v-card-text>
                <OrderInfoChief
                  @setNewData="setNewData"
                  :currency="currency"
                  :order="item"
                ></OrderInfoChief>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }"> </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "@vue/composition-api";
import { deleteOrder, getDesignerOrders, getMagazines, getOrders } from "@/ApiManager";
import { getCurrency } from "@/ApiManager";
import OrderInfo from "./OrderInfoChief.vue";
import OrderInfoChief from "./OrderInfoChief.vue";

export default {
  computed: {
    getProducts() {
      return this.products;
    },
  },
  data() {
    return {
      search: "",
      designerId: 0,
      dialog: false,
      delete_dialog: false,
      currency: "",
      magazine_names: [],
      single_name: [],
      headers: [
        {
          align: "start",
          value: "name",
        },
        { text: this.translate("Order Number"), value: "id" },
        { text: this.translate("Order Created"), value: "order_created_at" },

        { text: this.translate("Deadline"), value: "deadline" },
        { text: this.translate("Magazine"), value: "magazine_name" },
        { text: this.translate("Order Status"), value: "order_status_formatted" },
      ],
      products: [],
    };
  },
  components: {
    OrderInfo,
    OrderInfoChief,
  },
  methods: {
    editOrder(order) {
      var _order = order;
      _order["stocks"] = JSON.stringify(_order.stocks);
      this.$router.push({ path: "/edit_order/", query: order });
    },
    setNewData(data) {
      this.products = data;
    },
    magazine_selected(magazine_name) {
      for (var i = 0; i < this.magazine_names.length; i++)
        if (this.magazine_names[i].name == magazine_name)
          this.edit_magazine = this.magazine_names[i].id;
      console.log(this.edit_magazine);
    },
    handleDelete(id) {
      deleteOrder(id);
      getCurrency().then((response) => {
        this.currency = response["currency"];
      });
      this.delete_dialog = false;
      this.products = this.products.filter(function (item) {
        return item.id != id;
      });
    },
  },
  created() {
    this.designerId = JSON.parse(localStorage.getItem("user")).id;
    //this.$store.state.socketio.on('orders_updated', (...args) => {
    //  getDesignerOrders(this.designerId).then(response => {
    //    this.products = response.data
    //    this.products.forEach(
    //      product => (
    //        (product.total_price = `${product.total_price} ${this.currency}`),
    //        (product.color = this.orderStatusToColor(product.order_status)),
    //        (product.order_status_formatted = this.orderStatusToText(product.order_status))
    //      ),
    //      (this.$store.state.loading = false),
    //    )
    //  })
    //})

    this.$store.state.loading = true;
    getCurrency().then((response) => {
      this.currency = response["currency"];
    });
    getOrders().then((response) => {
      this.products = response.data;
      this.products.forEach(
        (product) => (
          (product.total_price = `${product.total_price} ${this.currency}`),
          (product.color = this.orderStatusToColor(product.order_status)),
          (product.order_status_formatted = this.orderStatusToText(product.order_status))
        ),
        (this.$store.state.loading = false)
      );

      this.products = this.products.filter(function (order) {
        return order.order_status == "choose_agents";
      });
    });

    getMagazines().then((response) => {
      this.magazine_names = response;
      response.forEach((names) => {
        this.single_name.push(names.name);
      });
    });
  },
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
