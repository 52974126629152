var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-lazy',[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.translate("Client")))]),_c('v-data-table',{attrs:{"dense":"","items-per-page":20,"headers":_vm.clientTableHeaders,"items":[_vm.order],"hide-default-footer":true,"item-key":"name"}}),_c('br'),_c('br'),_c('v-divider'),_c('br'),_c('v-row',_vm._l((_vm.images),function(image){return _c('div',{key:image.id},[_c('div',{staticStyle:{"overflow":"visible","position":"relative","min-width":"110px","max-width":"110px","min-height":"110px","max-height":"110px","margin":"5px","padding":"5px","border-style":"dashed"}},[_c('a',{attrs:{"href":image.image_url,"target":"_blank"}},[(_vm.isFileImage(image))?_c('v-img',{staticStyle:{"height":"95px","width":"100%"},attrs:{"src":image.image_url}}):_c('span',{staticStyle:{"-webkit-line-clamp":"7","display":"-webkit-box","line-height":"1","-webkit-box-orient":"vertical","overflow":"hidden","text-overflow":"ellipsis","line-break":"anywhere"}},[_vm._v(" "+_vm._s(image.filename))])],1)])])}),0),_c('br'),_c('div',[_c('br'),_c('h3',[_vm._v(_vm._s(_vm.translate("Stocks")))]),_c('v-divider'),_c('v-data-table',{attrs:{"items-per-page":20,"dense":"","headers":_vm.stocksTableHeaders,"items":_vm.stocks,"hide-default-footer":true,"item-key":"name"},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"overflow":"visible","position":"relative","min-width":"55px","max-width":"55px","min-height":"55px","max-height":"55px","margin":"5px","padding":"5px","border-style":"dashed"}},[_c('a',{attrs:{"href":item.image_url,"target":"_blank"}},[_c('v-img',{staticStyle:{"height":"45px","width":"100%"},attrs:{"src":item.image_url}})],1)])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.amount)+" "+_vm._s(item.measurement_name))]}},{key:"item.quantity_price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.quantity_price)+" "+_vm._s(_vm.currency))]}},{key:"item.tax",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.tax)+"%")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.discount)+"%")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.calculateTotal(item))+" "+_vm._s(_vm.currency))]}}])}),(_vm.services.length != 0)?_c('h3',[_vm._v(_vm._s(_vm.translate("Services")))]):_vm._e(),(_vm.services.length != 0)?_c('v-data-table',{attrs:{"items-per-page":20,"dense":"","headers":_vm.servicesTableHeaders,"items":_vm.services,"hide-default-footer":true,"item-key":"name"},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.price)+" "+_vm._s(_vm.currency))]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.price * item.amount)+" "+_vm._s(_vm.currency))]}}],null,false,777292827)}):_vm._e()],1),_c('div',[_c('br'),_c('div',{staticStyle:{"display":"flex","margin-bottom":"5px"}},[_c('span',{staticStyle:{"display":"inline-flex","align-items":"center"}},[_c('h3',[_vm._v(_vm._s(_vm.translate("Designers")))])]),_c('span',{staticStyle:{"min-width":"10px"}}),_c('span',[_c('v-select',{staticClass:"fit",attrs:{"outlined":"","hide-details":"","label":_vm.translate('Designers'),"dense":"","filled":"","item-text":"text","item-value":"value","multiple":"","chips":"","items":_vm.getDesigners},on:{"change":_vm.designersChanged},model:{value:(_vm.selectedDesigners),callback:function ($$v) {_vm.selectedDesigners=$$v},expression:"selectedDesigners"}})],1)]),_c('v-divider'),_c('br'),(_vm.designers.length != 0)?_c('v-data-table',{attrs:{"items-per-page":20,"dense":"","headers":_vm.designerTableHeaders,"items":_vm.designers,"hide-default-footer":true,"item-key":"name"},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium v-chip v-size--small",class:item.status == 0 ? 'error' : 'success'},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(item.status == 0 ? "Designing" : "Done")+" ")])])]}}],null,false,754332471)}):_vm._e()],1),_c('div',{staticStyle:{"display":"flex","margin-bottom":"5px"}},[_c('span',[_c('h2',[_vm._v(_vm._s(_vm.translate("Productions")))])]),_c('span',{staticStyle:{"min-width":"10px"}}),_c('span',[_c('v-select',{class:_vm.selectedProductions.length == 0 ? '' : 'shrinked',attrs:{"outlined":"","hide-details":"","label":_vm.translate('Productions'),"dense":"","filled":"","item-text":"text","item-value":"value","chips":"","multiple":"","items":_vm.getProductions},on:{"change":_vm.productionsChanged},model:{value:(_vm.selectedProductions),callback:function ($$v) {_vm.selectedProductions=$$v},expression:"selectedProductions"}})],1)]),_c('v-divider'),_c('br'),(_vm.production.length != 0)?_c('v-data-table',{attrs:{"items-per-page":20,"dense":"","headers":_vm.productionTableHeaders,"items":_vm.production,"hide-default-footer":true,"item-key":"name"}}):_vm._e(),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"success",attrs:{"disabled":_vm.selectedDesigners.length == 0 || _vm.selectedProductions.length == 0},on:{"click":function($event){return _vm.done()}}},[_vm._v(" Proceed")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }