<template>
  <div>
    <Dashboard v-if="getRole == 'manager'" />
    <orders_designerVue v-if="getRole == 'designer'" />

    <chief_orders v-if="getRole == 'chief_designer'" />

    <OrdersProductionVue v-if="getRole == 'production'" />
  </div>
</template>

<script>
import Dashboard from "./dashboard/Dashboard.vue";
import orders_designerVue from "./pages/orders/designer/OrdersDesigner.vue";
import chief_orders from "./pages/orders/chief_designer/OrdersChief.vue";
import OrdersProductionVue from "./pages/orders/production/OrdersProduction.vue";
export default {
  computed: {
    getRole() {
      return JSON.parse(localStorage.getItem("user")).role;
    },
  },
  components: { chief_orders, Dashboard, orders_designerVue, OrdersProductionVue },
};
</script>
