<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">{{ title }}</span>
      <v-spacer></v-spacer>
      <v-btn icon small class="me-n3 mt-n2">
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="3" md="3" class="d-flex align-center">
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation('Orders').color"
            rounded
            class="elevation-1"
          >
            <v-icon dark color="white" size="30">
              {{ resolveStatisticsIconVariation("Orders").icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">{{ translate("Orders") }}</p>
            <h3 class="text-xl font-weight-semibold">
              {{ nFormatter(api_data.orders_amount) }}
            </h3>
          </div>
        </v-col>
        <v-col cols="3" md="3" class="d-flex align-center">
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation('Products Sold').color"
            rounded
            class="elevation-1"
          >
            <v-icon dark color="white" size="30">
              {{ resolveStatisticsIconVariation("Products Sold").icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">{{ translate("Products Sold") }}</p>
            <h3 class="text-xl font-weight-semibold">
              {{ nFormatter(api_data.products_sold) }}
            </h3>
          </div>
        </v-col>
        <v-col cols="3" md="3" class="d-flex align-center">
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation('Services').color"
            rounded
            class="elevation-1"
          >
            <v-icon dark color="white" size="30">
              {{ resolveStatisticsIconVariation("Services").icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">{{ translate("Services") }}</p>
            <h3 class="text-xl font-weight-semibold">
              {{
                nFormatter(
                  month
                    ? api_data.services_amount_current_month
                    : api_data.services_amount_current_year
                )
              }}
              {{ currency }}
            </h3>
          </div>
        </v-col>
        <v-col cols="3" md="3" class="d-flex align-center">
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation('Clients').color"
            rounded
            class="elevation-1"
          >
            <v-icon dark color="white" size="30">
              {{ resolveStatisticsIconVariation("Clients").icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">{{ translate("Clients") }}</p>
            <h3 class="text-xl font-weight-semibold">
              {{
                nFormatter(
                  month ? api_data.clients_current_month : api_data.clients_current_year
                )
              }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from "@mdi/js";

export default {
  props: ["api_data", "month", "currency", "title"],
  setup() {
    const statisticsData = [
      {
        title: "Orders",
        total: "245k",
      },
      {
        title: "Products Sold",
        total: "1.54k",
      },
      {
        title: "Profit",
        total: "$88k",
      },
    ];

    const resolveStatisticsIconVariation = (data) => {
      if (data === "Orders") return { icon: mdiTrendingUp, color: "primary" };
      if (data === "Products Sold") return { icon: mdiLabelOutline, color: "warning" };
      if (data === "Services") return { icon: mdiCurrencyUsd, color: "info" };
      if (data === "Taxes") return { icon: mdiCurrencyUsd, color: "error" };

      return { icon: mdiAccountOutline, color: "success" };
    };

    return {
      statisticsData,
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
      },
    };
  },
};
</script>
