<template>
  <div>

    <div align="center">
      <v-btn-toggle v-on:change="toggled" v-model="toggle" divided>
        <v-btn>Active Orders</v-btn>
        <v-btn>Completed Orders</v-btn>
      </v-btn-toggle>
    </div>
    <br />
    <div class="d-flex justify-end" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'" flat tile>
      <div style="width: 60%"></div>

      <v-select class="pa-2" outlined filled dense v-model="dateRange" @input="(dateRange) => dateRangeChanged(dateRange)"
        :items="[
          'This Month',
          'Last Month',
          'Today',
          'Yesterday',
          'This Year',
          'Last Year',
          'Custom Date',
        ]"></v-select>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
        transition="scale-transition" offset-y min-width="100px" width="200px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="date" label="Custom Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
            v-on="on"></v-text-field>
        </template>
        <v-date-picker range v-model="date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="dateRangeChanged(date, 'customDate')">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-card>
      <v-card-title>
        {{ translate("Orders") }}
        <v-spacer></v-spacer>

        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table sort-by.sync="order_status" sort-desc.sync="true" :search="search" :items-per-page="20"
        :headers="headers" :items="getProducts" class="elevation-1">
        <template v-slot:item.order_status_formatted="{ item }">
          <span class="font-weight-medium v-chip v-size--small" :class="item.color"><span class="v-chip__content">
              {{ item.order_status_formatted }}
            </span></span>
        </template>
        <template v-slot:item.order_created_at="{ item }">
          <span>{{ formatDate(item.order_created_at) }}</span>&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"><span class="v-chip__content">
              {{ timeAgo(item.order_created_at) }}
            </span></span>
        </template>
        <template v-slot:item.deadline="{ item }">
          <span>{{ formatDate(item.deadline) }}</span>&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"><span class="v-chip__content"> {{
            timeAgo(item.deadline) }} </span></span>
        </template>
        <template v-slot:item.id="{ item }">
          <v-dialog width="60%">
            <template v-slot:activator="{ on, attrs }">
              <a color="red lighten-2" dark v-bind="attrs" v-on="on">
                <span style="font-weight: bold">#{{ item.id }}</span>
              </a>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">{{ translate("Order") }} #{{ item.id }}
                <v-col v-if="item.has_invoice == 1">
                  <v-btn style="margin-top: 5px" dense color="warning" @click="openInvoice(item)">Invoice</v-btn>
                </v-col>
              </v-card-title>
              <v-card-text>
                <OrderInfo @setNewData="setNewData" :currency="currency" :order="item"></OrderInfo>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }"> </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "@vue/composition-api";
import { deleteOrder, getDesignerOrders, getMagazines, getOrders, openOrderInvoice } from "@/ApiManager";
import { getCurrency } from "@/ApiManager";
import OrderInfo from "./OrderInfoDesigner.vue";

export default {
  computed: {
    getProducts() {
      return this.products;
    },
  },
  data() {
    return {
      selectedDate: null,
      date: null,
      dateRange: "This Month",
      menu: null,
      toggle: 0,
      search: "",
      designerId: 0,
      dialog: false,
      delete_dialog: false,
      currency: "",
      magazine_names: [],
      single_name: [],
      headers: [
        {
          align: "start",
          value: "name",
        },
        { text: this.translate("Order Number"), value: "id" },
        { text: this.translate("Deadline"), value: "deadline" },
        { text: this.translate("Magazine"), value: "magazine_name" },
        { text: this.translate("Order Status"), value: "order_status_formatted" },
      ],
      products: [],
    };
  },
  components: {
    OrderInfo,
  },
  methods: {
    async openInvoice(order) {
      const externalPageUrl = await openOrderInvoice(order.id);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain; charset=UTF-8");
      fetch(externalPageUrl, myHeaders)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          let decoder = new TextDecoder("UTF-8");
          let text = decoder.decode(buffer);
          const externalPage = window.open("", "_blank");
          externalPage.document.write(text);
          externalPage.document.close();
          externalPage.print();
        });
    },
    dateRangeChanged(dateRange, customDate) {
      if (dateRange == "Custom Date" && customDate == undefined) return;
      this.$store.state.loading = true;
      console.log(customDate);
      if (customDate != undefined) {
        this.dateRange = "Custom Date";
      }
      this.getDesignerOrder();
    },
    toggled() {
      this.$store.state.loading = true;
      this.getDesignerOrder();
    },
    editOrder(order) {
      var _order = order;
      _order["stocks"] = JSON.stringify(_order.stocks);
      this.$router.push({ path: "/edit_order/", query: order });
    },
    setNewData(data) {
      this.products = data;
    },
    magazine_selected(magazine_name) {
      for (var i = 0; i < this.magazine_names.length; i++)
        if (this.magazine_names[i].name == magazine_name)
          this.edit_magazine = this.magazine_names[i].id;
      console.log(this.edit_magazine);
    },
    handleDelete(id) {
      deleteOrder(id);
      getCurrency().then((response) => {
        this.currency = response["currency"];
      });
      this.delete_dialog = false;
      this.products = this.products.filter(function (item) {
        return item.id != id;
      }); 
    },
    getDesignerOrder() {
      var status = this.toggle == 0 ? "'designing'" : "'completed','choose_devpos','driver_picking_up','driver_delivering','driver_liquidating','company_delivering','liquidating'";
      getDesignerOrders(this.designerId, status, this.dateRange).then((response) => {
        console.log(response.data);
        this.products = response.data;
        this.products.forEach(
          (product) => (
            (product.total_price = `${product.total_price} ${this.currency}`),
            (product.color = this.orderStatusToColor(product.order_status)),
            (product.order_status_formatted = this.orderStatusToText(
              product.order_status
            ))
          ),
          (this.$store.state.loading = false)
        );
      });
    },
  },
  async created() {
    this.designerId = JSON.parse(localStorage.getItem("user")).id;
    //this.$store.state.socketio.on('orders_updated', (...args) => {
    //  getDesignerOrders(this.designerId).then(response => {
    //    this.products = response.data
    //    this.products.forEach(
    //      product => (
    //        (product.total_price = `${product.total_price} ${this.currency}`),
    //        (product.color = this.orderStatusToColor(product.order_status)),
    //        (product.order_status_formatted = this.orderStatusToText(product.order_status))
    //      ),
    //      (this.$store.state.loading = false),
    //    )
    //  })
    //})

    this.$store.state.loading = true;
    getCurrency().then((response) => {
      this.currency = response["currency"];
    });
    this.getDesignerOrder();
    getMagazines().then((response) => {
      this.magazine_names = response;
      response.forEach((names) => {
        this.single_name.push(names.name);
      });
    });
  },
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
