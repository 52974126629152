<template>
  <v-lazy>
    <div>
    
      <h3>{{ translate("Client") }}</h3>
      <v-data-table dense :items-per-page="20" :headers="clientTableHeaders" :items="[order]" :hide-default-footer="true"
        item-key="name"></v-data-table>

      <br />
      <br />

      <v-divider></v-divider>
      <br />

      <v-row>
        <div v-for="image in images" :key="image.id">
          <div style="
              overflow: visible;
              position: relative;
              min-width: 110px;
              max-width: 110px;
              min-height: 110px;
              max-height: 110px;
              margin: 5px;
              padding: 5px;
              border-style: dashed;
            ">
            <a :href="image.image_url" target="_blank"><v-img v-if="isFileImage(image)" style="height: 95px; width: 100%"
                :src="image.image_url"></v-img>
              <span v-else style="
                  -webkit-line-clamp: 7;
                  display: -webkit-box;
                  line-height: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-break: anywhere;
                ">
                {{ image.filename }}</span>
            </a>
          </div>
        </div>
      </v-row>
      <br />
      <div>
        <h3>{{ translate("Service Files") }} <a @click="openServiceImage">upload</a></h3>
        <v-data-table :items-per-page="20" dense :headers="[
          { text: this.translate('ID'), value: 'id' },
          { text: this.translate('File'), value: 'filename' },
          { text: this.translate('Actions'), value: 'actions' },
        ]" :items="serviceImages" :hide-default-footer="true" item-key="name">
          <template v-slot:item.filename="{ item }"><a :href="getDownloadServiceFileUrlLocal(item.filename)"
              target="_blank">{{
                item.filename
              }}</a></template>
          <template v-slot:item.actions="{ item }">
            <v-icon @click.stop="deleteServiceFile(item.id)" small style="color: var(--v-error-base)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <br />
        <h3>{{ translate("Stocks") }}</h3>
        <v-divider></v-divider>
        <v-data-table :items-per-page="20" dense :headers="stocksTableHeaders" :items="stocks" :hide-default-footer="true"
          item-key="name">
          <template v-slot:item.image="{ item }">
            <div style="
                overflow: visible;
                position: relative;
                min-width: 55px;
                max-width: 55px;
                min-height: 55px;
                max-height: 55px;
                margin: 5px;
                padding: 5px;
                border-style: dashed;
              ">
              <a :href="item.image_url" target="_blank"><v-img style="height: 45px; width: 100%"
                  :src="item.image_url"></v-img></a>
            </div>
          </template>
          <template v-slot:item.amount="{ item }">{{ item.amount }} {{ item.measurement_name }}</template>
          <template v-slot:item.quantity_price="{ item }">{{ item.quantity_price }} {{ currency }}</template>
          <template v-slot:item.tax="{ item }">{{ item.tax }}%</template>
          <template v-slot:item.discount="{ item }">{{ item.discount }}%</template>
          <template v-slot:item.total="{ item }">{{ calculateTotal(item) }} {{ currency }}</template>
        </v-data-table>
        <h3 v-if="services.length != 0">{{ translate("Services") }}</h3>
        <v-data-table :items-per-page="20" dense v-if="services.length != 0" :headers="servicesTableHeaders"
          :items="services" :hide-default-footer="true" item-key="name">
          <template v-slot:item.price="{ item }">{{ item.price }} {{ currency }}</template>
          <template v-slot:item.total="{ item }">{{ item.price * item.amount }} {{ currency }}</template>
        </v-data-table>
      </div>

      <div>
        <br />
        <div style="display: flex; margin-bottom: 5px">
          <span style="display: inline-flex; align-items: center">
            <h3>{{ translate("Designers") }}</h3>
          </span>
          <span style="min-width: 10px"></span>
          <span><v-select outlined hide-details class="fit" :label="translate('Designers')" dense filled item-text="text"
              item-value="value" v-model="selectedDesigners" multiple chips v-on:change="designersChanged"
              :items="getDesigners"></v-select></span>
        </div>
        <v-divider></v-divider>
        <br />
        <v-data-table :items-per-page="20" v-if="designers.length != 0" dense :headers="designerTableHeaders"
          :items="designers" :hide-default-footer="true" item-key="name">
          <template v-slot:item.status="{ item }">
            <span class="font-weight-medium v-chip v-size--small" :class="item.status == 0 ? 'error' : 'success'"><span
                class="v-chip__content">
                {{ item.status == 0 ? "Designing" : "Done" }}
              </span></span>
          </template></v-data-table>
      </div>
      <div>
        <br />
        <h3>Productions</h3>
        <v-divider></v-divider>

        <v-data-table :items-per-page="20" v-if="production.length != 0" dense :headers="designerTableHeaders"
          :items="production" :hide-default-footer="true" item-key="name"></v-data-table>
      </div>
      <div style="padding-top: 10px"></div>
      <v-divider></v-divider>
      <div style="padding-bottom: 15px"></div>

      <div class="text-left" v-if="order.order_status != 'completed' && isLocked!=true">
        <v-row justify="end" align-content="space-between">
          <v-col align-self="start">
            <v-row>
              <v-text-field style="padding-right: 5px" v-model="returnReason" :label="translate('return reason')"
                clear-icon="shrink" outlined dense></v-text-field>
              <v-btn class="warning" :disabled="returnReason == null" @click="callReturn()">
                Return</v-btn></v-row></v-col>
          <v-col></v-col>
          <v-col align-self="end">
            <v-row>
              <v-text-field style="padding-right: 5px" v-model="delayReason" :label="translate('delay reason')"
                clear-icon="shrink" outlined dense></v-text-field>
              <v-btn :disabled="isProceedButtonDisabled" @click="
                getCurrentDesignerUser.status == 1 ? toggleStatus(0) : toggleStatus(1)
                " :class="getCurrentDesignerUser.status == 1 ? 'error' : 'success'">
                {{ getCurrentDesignerUser.status == 1 ? "Not Done" : "Done" }}</v-btn></v-row></v-col>
        </v-row>
      </div>
      <input ref="serviceImageuploader" class="d-none" type="file" accept="" @change="uploadServiceImage" />
    </div>
  </v-lazy>
</template>
<script>
import { LMap, LTileLayer, LMarker, LTooltip, LPolyline, LIcon } from "vue2-leaflet";
import { Icon } from "leaflet";
import { mdiGoogle } from "@mdi/js";

import axios from "axios";
import {
  assignOrderToDriver,
  changeOrderStatus,
  getOrders,
  insertNewOrderImage,
  removeOrderImage,
  getUsers,
  moveOrder,
  getOrderInfo,
  toggleOrderDesigner,
  getDesignerOrders,
  changeOrderDesigners,
  getApprovedUsers,
  returnOrder,
  uploadServiceImage,
  getDownloadServiceFileUrl,
  deleteServiceImage,
} from "@/ApiManager";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  props: ["order", "currency"],

  methods: {
    
    openServiceImage() {
      this.$refs.serviceImageuploader.click();
    },
    async uploadServiceImage(data) {
      this.$store.state.loading = true;

      var file = data.target.files[0];
      await uploadServiceImage(file, this.order.id);
      await this.getNewData();
      this.$store.state.loading = false;
    },
    getDownloadServiceFileUrlLocal(filename) {
      return getDownloadServiceFileUrl(this.order.id, filename);
    },
    async deleteServiceFile(id) {
      this.$store.state.loading = true;

      await deleteServiceImage(id);
      await this.getNewData();

      this.$store.state.loading = false;
    },
    isFileImage(file) {
      const fileType = file["filetype"];
      const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
      if (validImageTypes.includes(fileType)) {
        return true;
      }
      return false;
    },
    async callReturn() {
      this.$store.state.loading = true;

      await returnOrder(this.order.id, this.returnReason);
      await this.getNewData();
      this.$store.state.loading = false;
    },
    async designersChanged() {
      this.$store.state.loading = true;
      await changeOrderDesigners(
        this.order.id,
        this.selectedDesigners,
        this.designers.map((designer) => designer.id)
      );
      await this.getNewData();
      this.$store.state.loading = false;
    },
    async toggleStatus(status) {
      await toggleOrderDesigner(
        this.getCurrentDesignerUser.id,
        this.order.id,
        status,
        this.order.sales_post,
        this.delayReason
      );
      this.getNewData();
    },
    calculateTotal(stock) {
      var price = 0;
      //get total price
      price = stock.amount * stock.quantity_price;
      //calculate discount
      price = price - (price * stock.discount) / 100;

      //calculate tax
      price = price + (price * stock.tax) / 100;
      return price;
    },
    async openImagePrompt() {
      this.$refs.uploader.click();
    },
    async orderInfo() {
      var result = await getOrderInfo(this.order.id);
      this.production = result.production;
      this.images = result.images;
      this.designers = result.designers;
      this.serviceImages = result.serviceImages;

      this.stocks = result.stocks;
      this.services = result.services;
      this.selectedDesigners = this.designers.map((designer) => designer.id);
      return;
    },

    async getNewData() {
      var orders = null;
      var response = await getDesignerOrders(JSON.parse(localStorage.getItem("user")).id);
      orders = response.data;
      orders.forEach(
        (order) => (
          (order.total_price = `${order.total_price} ${this.currency}`),
          (order.color = this.orderStatusToColor(order.order_status)),
          (order.order_status_formatted = this.orderStatusToText(order.order_status))
        )
      );
      await this.orderInfo();
      this.$store.state.loading = false;
      this.$emit("setNewData", orders);
    },
    async orderStatusChanged(orderStatus) {
      this.$store.state.loading = true;

      var response = await changeOrderStatus(this.order.id, orderStatus);
      this.getNewData();
    },
  },
  data() {
    return {
      serviceImages: [],

      delayReason: null,
      returnReason: null,

      users: [],
      images: [],
      stocks: [],
      services: [],
      production: [],
      designers: [],
      selectedDesigners: [],

      orderStatuses: [
        { text: this.translate("Processing"), value: "processing" },
        { text: this.translate("Returned"), value: "returned" },
        { text: this.translate("Waiting for a Driver"), value: "waiting_for_a_driver" },
        { text: this.translate("Client Picking Up"), value: "client_picking_up" },
        { text: this.translate("Company Delivering"), value: "company_delivering" },
        { text: this.translate("Driver Picking up"), value: "driver_picking_up" },
        { text: this.translate("Driver Delivering"), value: "driver_delivering" },
        { text: this.translate("Completed"), value: "completed" },
      ],

      clientTableHeaders: [
        { text: "ID", value: "client_id" },
        { text: this.translate("Name"), value: "client_name" },
        { text: this.translate("Phone Number"), value: "client_phone" },
      ],
      designerTableHeaders: [
        { text: "ID", value: "id" },
        { text: this.translate("Name"), value: "name" },
        { text: this.translate("Phone Number"), value: "phone_number" },
        { text: this.translate("Status"), value: "status" },
      ],
      stocksTableHeaders: [
        { text: this.translate("Image"), value: "image" },

        { text: this.translate("Product"), value: "product_name" },
        { text: this.translate("Category"), value: "category_name" },
        { text: this.translate("Machine"), value: "machine_name" },

        { text: this.translate("Quantity Price"), value: "quantity_price" },
        { text: this.translate("Amount"), value: "amount" },
        { text: this.translate("Discount"), value: "discount" },
        { text: this.translate("Tax"), value: "tax" },
        { text: this.translate("Total"), value: "total" },
      ],
      servicesTableHeaders: [
        { text: this.translate("Name"), value: "name" },
        { text: this.translate("Text"), value: "text" },
        { text: this.translate("Note"), value: "notes" },

        { text: this.translate("Price"), value: "price" },
        { text: this.translate("Amount"), value: "amount" },
        { text: this.translate("Total"), value: "total" },
      ],
    };
  },
  computed: {
    isLocked(){
     return this.order.order_status!='designing'
    },
    isProceedButtonDisabled() {
      if (this.isDeadlineExpired == false) {
        console.log("1");
        return false;
      }
      if (this.isDeadlineExpired && this.delayReason == null) {
        console.log("2");
        return true;
      }
      console.log("3");
      return false;
    },
    isDeadlineExpired() {
      let deadline = new Date(this.order.deadline).getTime();
      let currentTime = new Date().getTime();
      if (deadline < currentTime) {
        return true;
      }
      return false;
    },
    getDesigners() {
      var designers = [];
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].role == "designer")
          designers.push({ text: this.users[i].name, value: this.users[i].id });
      }
      return designers;
    },
    getCurrentDesignerUser() {
      var currentUserId = JSON.parse(localStorage.getItem("user")).id;

      for (var i = 0; i < this.designers.length; i++) {
        if (this.designers[i].designer_id == currentUserId) {
          return this.designers[i];
        }
      }
    },
  },
  components: {
    LMap,
    mdiGoogle,
    LTileLayer,
    LMarker,
    LTooltip,
    LPolyline,
    LIcon,
  },
  async created() {
    this.$store.state.loading = true;

    await this.orderInfo();
    getApprovedUsers().then((response) => {
      this.users = response;
    });
    this.$store.state.loading = false;
  },
};
</script>
<style>
.v-input .v-input__control .v-text-field__details .v-messages {
  height: 0px;
}

.customBtn {
  height: 25px;
  width: 25px;
}

.v-select__selections input {
  display: none;
  width: 5px;
}
</style>
