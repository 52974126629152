<template>
  <div>
    <div align="center">
      <v-btn-toggle v-on:change="toggled" v-model="toggle" divided>
        <v-btn>Active Orders</v-btn>
        <v-btn>Completed Orders</v-btn>
      </v-btn-toggle>
    </div>
    <br />
    <div class="d-flex justify-end" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'" flat tile>
      <div style="width: 60%"></div>

      <v-select class="pa-2" outlined filled dense v-model="dateRange" @input="(dateRange) => dateRangeChanged(dateRange)"
        :items="[
          'This Month',
          'Last Month',
          'Today',
          'Yesterday',
          'This Year',
          'Last Year',
          'Custom Date',
        ]"></v-select>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
        transition="scale-transition" offset-y min-width="100px" width="200px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="date" label="Custom Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
            v-on="on"></v-text-field>
        </template>
        <v-date-picker range v-model="date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="dateRangeChanged(date, 'customDate')">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-card>
      <v-card-title>
        {{ translate("Orders") }}
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table sort-by.sync="order_status" sort-desc.sync="true" :search="search" :items-per-page="20"
        :headers="headers" :items="getProducts" class="elevation-1">
        <template v-slot:item.order_status_formatted="{ item }">
          <span class="font-weight-medium v-chip v-size--small" :class="item.color"><span class="v-chip__content">
              {{ item.order_status_formatted }}
            </span></span>
        </template>
        <template v-slot:item.order_created_at="{ item }">
          <span>{{ formatDate(item.order_created_at) }}</span>&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"><span class="v-chip__content">
              {{ timeAgo(item.order_created_at) }}
            </span></span>
        </template>
        <template v-slot:item.deadline="{ item }">
          <span>{{ formatDate(item.deadline) }}</span>&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"><span class="v-chip__content"> {{
            timeAgo(item.deadline) }} </span></span>
        </template>
        <template v-slot:item.order_status="{ item }">
          <span class="font-weight-medium v-chip v-size--small" :class="item.color"><span class="v-chip__content">
              {{ orderStatusToText(item.order_status) }}
            </span></span>
        </template>
        <template v-slot:item.id="{ item }">
          <v-dialog width="60%">
            <template v-slot:activator="{ on, attrs }">
              <a color="red lighten-2" dark v-bind="attrs" v-on="on">
                <span style="font-weight: bold">#{{ item.id }}</span>
              </a>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">{{ translate("Order") }} #{{ item.id }}
                <v-col v-if="item.has_invoice == 1">
                  <v-btn style="margin-top: 5px" dense color="warning" @click="openInvoice(item)">Invoice</v-btn>
                </v-col>
              </v-card-title>
              <v-card-text>
                <OrderInfoProduction @openAskForDevposDataDialog="openAskForDevposDataDialog" @setNewData="setNewData"
                  :currency="currency" :order="item"></OrderInfoProduction>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }"> </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="askForDevposDataDialog" width="60%">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">{{ translate("Additional Information") }}

        </v-card-title>
        <v-card-text>
          <v-form v-model="first_form" ref="first_form" lazy-validation class="v-form multi-col-validation">
            <v-row>
              <v-col>
                <v-text-field required v-model="weight" :label="translate('Weight')" filled clear-icon="shrink"
                  :rules="emptyRule" outlined type="name" dense></v-text-field>
              </v-col>
              <v-col>
                <v-select style="min-width: 150px" filled outlined :label="translate('Package Type')" dense
                  v-model="package_type" :items="[
                    'PACKET',
                    'DOCUMENT'
                  ]"></v-select>
              </v-col>
              <v-col>
                <v-text-field required v-model="package_quantity" :label="translate('Package Quantity')" filled
                  clear-icon="shrink" :rules="emptyRule" outlined type="name" dense></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select style="min-width: 150px" filled outlined :label="translate('Devpos')" dense v-model="devpos"
                  :items="[
                    {
                      'text': 'Devpos',
                      'value': 1
                    },
                    {
                      'text': 'No Devpos',
                      'value': 0
                    }]"></v-select>
              </v-col>
              <v-col>
              </v-col>
            </v-row>
            <v-row justify="end" align-content="space-between">
              <v-btn color="success" @click="send_order_to_adex">
                Send Order to Adex
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "@vue/composition-api";
import {
  deleteOrder,
  getDesignerOrders,
  getMagazines,
  getOrders,
  getProductionOrders,
  sendOrderToAdex,
} from "@/ApiManager";
import { getCurrency, openOrderInvoice } from '@/ApiManager';
import OrderInfoProduction from "./OrderInfoProduction.vue";

export default {
  computed: {
    getProducts() {
      return this.products;
    },
  },
  data() {
    return {
      devpos: null,
      package_type: null,
      package_quantity: 1,
      weight: null,

      emptyRule: [(v) => !!v || "Can't be empty!"],
      AskForDevposDataOrder: null,
      askForDevposDataDialog: false,
      askForDevposDataDialog: false,
      selectedDate: null,
      date: null,
      dateRange: "This Month",
      menu: null,
      first_form: false,
      toggle: 0,
      search: "",
      designerId: 0,
      dialog: false,
      delete_dialog: false,
      currency: "",
      magazine_names: [],
      single_name: [],
      headers: [
        {
          align: "start",
          value: "name",
        },
        { text: this.translate("Order Number"), value: "id" },
        { text: this.translate("Deadline"), value: "deadline" },
        { text: this.translate("Customer Name"), value: "client_name" },
        { text: this.translate("Magazine"), value: "magazine_name" },
        { text: this.translate("Order Status"), value: "order_status" },
        { text: this.translate("Total Price"), value: "total_price" },
      ],
      products: [],
    };
  },
  components: {
    OrderInfoProduction,
  },
  methods: {
    async send_order_to_adex() {
      this.$store.state.loading = true;
      await sendOrderToAdex(this.AskForDevposDataOrder.id, this.weight, this.package_type, this.package_quantity, this.devpos)
      await this.getOrdersLocal()
      this.askForDevposDataDialog = false;
      this.$store.state.loading = false;
    },
    async openAskForDevposDataDialog(order) {
      this.AskForDevposDataOrder = order;
      this.askForDevposDataDialog = true
    },
    async openInvoice(order) {
      const externalPageUrl = await openOrderInvoice(order.id);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain; charset=UTF-8");
      fetch(externalPageUrl, myHeaders)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          let decoder = new TextDecoder("UTF-8");
          let text = decoder.decode(buffer);
          const externalPage = window.open("", "_blank");
          externalPage.document.write(text);
          externalPage.document.close();
          externalPage.print();
        });
    },
    dateRangeChanged(dateRange, customDate) {
      if (dateRange == "Custom Date" && customDate == undefined) return;
      this.$store.state.loading = true;
      console.log(customDate);
      if (customDate != undefined) {
        this.dateRange = "Custom Date";
      }
      this.getOrdersLocal();
    },
    toggled() {
      this.$store.state.loading = true;
      this.getOrdersLocal();
    },
    editOrder(order) {
      var _order = order;
      _order["stocks"] = JSON.stringify(_order.stocks);
      this.$router.push({ path: "/edit_order/", query: order });
    },
    setNewData(data) {
      this.products = data;
    },
    magazine_selected(magazine_name) {
      for (var i = 0; i < this.magazine_names.length; i++)
        if (this.magazine_names[i].name == magazine_name)
          this.edit_magazine = this.magazine_names[i].id;
      console.log(this.edit_magazine);
    },
    handleDelete(id) {
      deleteOrder(id);
      getCurrency().then((response) => {
        this.currency = response["currency"];
      });
      this.delete_dialog = false;
      this.products = this.products.filter(function (item) {
        return item.id != id;
      });
    },
    getOrdersLocal() {
      var status = this.toggle == 0 ? "'production'" : "'completed','choose_devpos','driver_picking_up','driver_delivering','driver_liquidating','company_delivering','liquidating'";

      getProductionOrders(this.designerId, status, this.dateRange).then((response) => {
        this.products = response.data;
        this.products.forEach(
          (product) => (
            (product.total_price = `${product.total_price} ${this.currency}`),
            (product.color = this.orderStatusToColor(product.order_status)),
            (product.order_status_formatted = this.orderStatusToText(
              product.order_status
            ))
          ),
          (this.$store.state.loading = false)
        );
      });
    },
  },
  created() {
    this.designerId = JSON.parse(localStorage.getItem("user")).id;
    //this.$store.state.socketio.on('orders_updated', (...args) => {
    //  getProductionOrders(this.designerId).then(response => {
    //    this.products = response.data
    //    this.products.forEach(
    //      product => (
    //        (product.total_price = `${product.total_price} ${this.currency}`),
    //        (product.color = this.orderStatusToColor(product.order_status)),
    //        (product.order_status_formatted = this.orderStatusToText(product.order_status))
    //      ),
    //      (this.$store.state.loading = false),
    //    )
    //  })
    //})

    this.$store.state.loading = true;
    getCurrency().then((response) => {
      this.currency = response["currency"];
    });
    this.getOrdersLocal();
    getMagazines().then((response) => {
      this.magazine_names = response;
      response.forEach((names) => {
        this.single_name.push(names.name);
      });
    });
  },
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
