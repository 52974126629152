var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"align":"center"}},[_c('v-btn-toggle',{attrs:{"divided":""},on:{"change":_vm.toggled},model:{value:(_vm.toggle),callback:function ($$v) {_vm.toggle=$$v},expression:"toggle"}},[_c('v-btn',[_vm._v("Active Orders")]),_c('v-btn',[_vm._v("Completed Orders")])],1)],1),_c('br'),_c('div',{staticClass:"d-flex justify-end",attrs:{"color":_vm.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4',"flat":"","tile":""}},[_c('div',{staticStyle:{"width":"60%"}}),_c('v-select',{staticClass:"pa-2",attrs:{"outlined":"","filled":"","dense":"","items":[
        'This Month',
        'Last Month',
        'Today',
        'Yesterday',
        'This Year',
        'Last Year',
        'Custom Date' ]},on:{"input":function (dateRange) { return _vm.dateRangeChanged(dateRange); }},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"100px","width":"200px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Custom Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.dateRangeChanged(_vm.date, 'customDate')}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translate("Orders"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"sort-by.sync":"order_status","sort-desc.sync":"true","search":_vm.search,"items-per-page":20,"headers":_vm.headers,"items":_vm.getProducts},scopedSlots:_vm._u([{key:"item.order_status_formatted",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium v-chip v-size--small",class:item.color},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(item.order_status_formatted)+" ")])])]}},{key:"item.order_created_at",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.order_created_at)))]),_vm._v("  "),_c('span',{staticClass:"font-weight-medium v-chip v-size--small primary"},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.timeAgo(item.order_created_at))+" ")])])]}},{key:"item.deadline",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.deadline)))]),_vm._v("  "),_c('span',{staticClass:"font-weight-medium v-chip v-size--small primary"},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.timeAgo(item.deadline))+" ")])])]}},{key:"item.order_status",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium v-chip v-size--small",class:item.color},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.orderStatusToText(item.order_status))+" ")])])]}},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('v-dialog',{attrs:{"width":"60%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"color":"red lighten-2","dark":""}},'a',attrs,false),on),[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("#"+_vm._s(item.id))])])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(_vm._s(_vm.translate("Order"))+" #"+_vm._s(item.id)+" "),(item.has_invoice == 1)?_c('v-col',[_c('v-btn',{staticStyle:{"margin-top":"5px"},attrs:{"dense":"","color":"warning"},on:{"click":function($event){return _vm.openInvoice(item)}}},[_vm._v("Invoice")])],1):_vm._e()],1),_c('v-card-text',[_c('OrderInfoProduction',{attrs:{"currency":_vm.currency,"order":item},on:{"openAskForDevposDataDialog":_vm.openAskForDevposDataDialog,"setNewData":_vm.setNewData}})],1),_c('v-divider')],1)],1)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return undefined}}])})],1),_c('v-dialog',{attrs:{"width":"60%"},model:{value:(_vm.askForDevposDataDialog),callback:function ($$v) {_vm.askForDevposDataDialog=$$v},expression:"askForDevposDataDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(_vm._s(_vm.translate("Additional Information"))+" ")]),_c('v-card-text',[_c('v-form',{ref:"first_form",staticClass:"v-form multi-col-validation",attrs:{"lazy-validation":""},model:{value:(_vm.first_form),callback:function ($$v) {_vm.first_form=$$v},expression:"first_form"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"required":"","label":_vm.translate('Weight'),"filled":"","clear-icon":"shrink","rules":_vm.emptyRule,"outlined":"","type":"name","dense":""},model:{value:(_vm.weight),callback:function ($$v) {_vm.weight=$$v},expression:"weight"}})],1),_c('v-col',[_c('v-select',{staticStyle:{"min-width":"150px"},attrs:{"filled":"","outlined":"","label":_vm.translate('Package Type'),"dense":"","items":[
                  'PACKET',
                  'DOCUMENT'
                ]},model:{value:(_vm.package_type),callback:function ($$v) {_vm.package_type=$$v},expression:"package_type"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"required":"","label":_vm.translate('Package Quantity'),"filled":"","clear-icon":"shrink","rules":_vm.emptyRule,"outlined":"","type":"name","dense":""},model:{value:(_vm.package_quantity),callback:function ($$v) {_vm.package_quantity=$$v},expression:"package_quantity"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{staticStyle:{"min-width":"150px"},attrs:{"filled":"","outlined":"","label":_vm.translate('Devpos'),"dense":"","items":[
                  {
                    'text': 'Devpos',
                    'value': 1
                  },
                  {
                    'text': 'No Devpos',
                    'value': 0
                  }]},model:{value:(_vm.devpos),callback:function ($$v) {_vm.devpos=$$v},expression:"devpos"}})],1),_c('v-col')],1),_c('v-row',{attrs:{"justify":"end","align-content":"space-between"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.send_order_to_adex}},[_vm._v(" Send Order to Adex ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }