<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <SalesByMagazines :api_data="computedData.magazine_monthly_data" :currency="currency" v-if="isloading">
        </SalesByMagazines>
      </v-col>

      <v-col>
        <v-row>
          <v-col>
            <DashboardStatisticsCard :title="translate('Monthly Statistics')" :month="true" :api_data="computedData"
              :currency="currency"></DashboardStatisticsCard>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="3">
        <SalesByProducts :api_data="computedData.product_monthly_data" :currency="currency"
          v-if="computedData.product_monthly_data != undefined"></SalesByProducts>
      </v-col>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">
        <SalesByMagazines :api_data="computedData.magazine_monthly_data" :currency="currency" v-if="isloading">
        </SalesByMagazines>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <DashboardStatisticsCard :title="translate('Yearly Statistics')" :api_data="computedData" :month="false"
              :currency="currency"></DashboardStatisticsCard>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="3">
        <SalesByProducts :api_data="computedData.product_monthly_data" :currency="currency"
          v-if="computedData.product_monthly_data != undefined"></SalesByProducts>
      </v-col>
    </v-row>
    <v-col> </v-col>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title> {{ translate("My Drivers") }} </v-card-title>

          <v-card-text>
            <v-row>
              <v-btn v-for="driver in getApprovedUsers" :key="driver.id" @click="focusOnDriver(driver)" class="ma-2"
                :color="isUserOnline(driver) ? 'error' : 'success'">
                {{ driver.name }}
              </v-btn>
            </v-row>
            <br />
          </v-card-text>
          <v-card-title> {{ translate("Active Orders") }} </v-card-title>
          <v-data-table sort-by.sync="order_status" sort-desc.sync="true" :search="search" :items-per-page="20"
            :headers="headers" :items="computedOrders" class="elevation-1">
            <template v-slot:footer.page-text>
              <v-row style="width: 300px">
                <v-col cols="5">
                  <h4>Orders: {{ totalOrders }}</h4>
                </v-col><v-col>
                  <h4>Total Price: {{ sum }} Lek</h4>
                </v-col></v-row>
            </template>
            <template v-slot:item.order_status="{ item }">
              <span class="font-weight-medium v-chip v-size--small" :class="item.color"><span class="v-chip__content">
                  {{ orderStatusToText(item.order_status) }}
                </span></span>
            </template>
            <template v-slot:item.deadline="{ item }">
              <span>{{ formatDate(item.deadline) }}</span>&nbsp;
              <span class="font-weight-medium v-chip v-size--small primary"><span class="v-chip__content"> {{
                timeAgo(item.deadline) }} </span></span>
            </template>
            <template v-slot:item.id="{ item }">
              <v-dialog width="60%">
                <template v-slot:activator="{ on, attrs }">
                  <a color="red lighten-2" dark v-bind="attrs" v-on="on">
                    {{ item.id }}
                  </a>
                </template>
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">{{ translate("Order") }} #{{ item.id }}</v-card-title>
                  <v-card-text>
                    <OrderInfo @setNewData="setNewData" :currency="currency" :order="item"></OrderInfo>
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <router-link :to="{ name: 'edit_order', query: { id: item.id } }">
                <v-icon style="color: var(--v-warning-base)" small> mdi-pencil </v-icon>
              </router-link>
              <v-dialog :retain-focus="false" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon @click.stop="item.delete_dialog = true" small style="color: var(--v-error-base)" v-bind="attrs"
                    v-on="on">
                    mdi-delete
                  </v-icon>
                </template>

                <v-card>
                  <v-card-title class="text-h5">{{ translate("Are you sure want to delete Order") }}
                    {{ item.id }}?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="delete_dialog = false">{{
                      translate("Cancel")
                    }}</v-btn>
                    <v-btn color="primary" @click="handleDelete(item.id)">{{
                      translate("OK")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiPoll,
  mdiLabelVariantOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
} from "@mdi/js";

// demos
import DashboardCongratulationJohn from "./DashboardCongratulationJohn.vue";
import DashboardStatisticsCard from "./DashboardStatisticsCard.vue";
import DashboardCardTotalEarning from "./DashboardCardTotalEarning.vue";
import DashboardCardSalesByCountries from "./DashboardCardSalesByProducts.vue";
import DashboardWeeklyOverview from "./DashboardWeeklyOverview.vue";
import DashboardDatatable from "./DashboardDatatable.vue";
import SalesByMagazines from "./SalesByMagazines.vue";
import SalesByProducts from "./SalesByProducts.vue";
import { LMap, LTileLayer, LMarker, LTooltip, LPolyline, LIcon } from "vue2-leaflet";
import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import Leaflet from "easy-vue-leaflet";
import axios from "axios";
import StatisticsCardVertical from "@/components/statistics-card/StatisticsCardVertical.vue";
import {
  getApprovedDrivers,
  getCurrency,
  getDashboardData,
  getOrders,
} from "@/ApiManager";
import OrderInfo from "@/views/pages/orders/OrderInfo.vue";

export default {
  computed: {
    totalOrders() {
      return this.orders.length;
    },
    sum() {
      var a = 0;
      for (var i = 0; i < this.orders.length; i++) {
        a += parseInt(this.orders[i].total_price);
      }
      return a;
    },
    computedOrders() {
      return this.orders;
    },
    getOnlineUsers() {
      var onlineUsers = [];
      var users = this.getApprovedUsers;
      for (var i = 0; i < users.length; i++) {
        if (this.isUserOnline(users[i]) == false) {
          onlineUsers.push(users[i]);
        }
      }
      return onlineUsers;
    },
    getCenter() {
      return this.center;
    },
    getApprovedUsers() {
      return this.approvedUsers;
    },
    isloading() {
      return this.$store.state.loading == false;
    },
    computedData() {
      this.currency;
      console.log(this.api_data);
      return this.api_data;
    },
  },
  components: {
    OrderInfo,
    mdiCurrencyUsd,
    StatisticsCardVertical,
    DashboardCongratulationJohn,
    DashboardStatisticsCard,
    DashboardCardTotalEarning,
    DashboardCardSalesByCountries,
    DashboardWeeklyOverview,
    DashboardDatatable,
    SalesByMagazines,
    SalesByProducts,
    leaflet: Leaflet,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    StatisticsCardVertical,

    LPolyline,
    LIcon,
  },
  mounted() {
    this.interval = setInterval(async () => {
      this.approvedUsers = await getApprovedDrivers();
      var raw_data = await getOrders(null);
      this.orders = raw_data.data;
      this.orders.forEach(
        (product) => (
          (product.total_price = `${product.total_price} ${this.currency}`),
          (product.color = this.orderStatusToColor(product.order_status)),
          (product.order_status_formatted = this.orderStatusToText(product.order_status))
        )
      );
    }, 3000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  async created() {
    this.$store.state.loading = true;
    getApprovedDrivers().then((val) => (this.approvedUsers = val));
    getCurrency().then((val) => (this.currency = val["currency"]));

    //this.$store.state.socketio.on('orders_updated', (...args) => {
    //  getOrders().then(response => {
    //    this.orders = response.data
    //    this.orders.forEach(
    //      product => (
    //        (product.total_price = `${product.total_price} ${this.currency}`),
    //        (product.color = this.orderStatusToColor(product.order_status)),
    //        (product.order_status_formatted = this.orderStatusToText(product.order_status))
    //      ),
    //    )
    //  })
    //})
    getOrders(null).then((response) => {
      this.orders = response.data;
      this.orders.forEach(
        (product) => (
          (product.total_price = `${product.total_price} ${this.currency}`),
          (product.color = this.orderStatusToColor(product.order_status)),
          (product.order_status_formatted = this.orderStatusToText(product.order_status))
        )
      );
    });

    this.api_data = await getDashboardData();

    this.$store.state.loading = false;
  },
  setup() {
    const totalProfit = {
      statTitle: "Total Profit",
      icon: mdiPoll,
      color: "success",
      subtitle: "Weekly Project",
      statistics: "$25.6k",
      change: "+42%",
    };

    const totalSales = {
      statTitle: "Refunds",
      icon: mdiCurrencyUsd,
      color: "secondary",
      subtitle: "Past Month",
      statistics: "$78",
      change: "-15%",
    };

    // vertical card options
    const newProject = {
      statTitle: "New Project",
      icon: mdiLabelVariantOutline,
      color: "primary",
      subtitle: "Yearly Project",
      statistics: "862",
      change: "-18%",
    };

    const salesQueries = {
      statTitle: "Sales Quries",
      icon: mdiHelpCircleOutline,
      color: "warning",
      subtitle: "Last week",
      statistics: "15",
      change: "-18%",
    };

    return {
      mdiCurrencyUsd,
      totalProfit,
      totalSales,
      newProject,
      salesQueries,
    };
  },
  data() {
    return {
      search: "",
      headers: [
        {
          align: "start",
          value: "name",
        },
        { text: this.translate("Order Number"), value: "id" },
        { text: this.translate("Deadline"), value: "deadline" },
        { text: this.translate("Customer Name"), value: "client_name" },
        { text: this.translate("Magazine"), value: "magazine_name" },
        { text: this.translate("Order Status"), value: "order_status" },
        { text: this.translate("Total Price"), value: "total_price" },
        { text: this.translate("Actions"), value: "actions" },
      ],
      interval: null,
      selectedOrder: { id: 0 },
      orderInfoDialog: false,
      orders: [],
      approvedUsers: [],
      api_data: {},
      currency: "",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 12,
      center: [41.3284575, 19.8178748],
      markerLatLng: [0, 0],
      leaflet_key: 0,
    };
  },
  methods: {
    setNewData(data) {
      console.log(data);
      this.orders = data;
    },
    openOrderDialog(order) {
      this.selectedOrder = order;
      this.orderInfoDialog = true;
    },
    centerUpdate(center) {
      this.center = center;
    },
    focusOnDriver(driver) {
      if (this.isUserOnline(driver)) return;
      this.center = [driver.lat, driver.lng];
    },
    focusOnClient(order) {
      this.center = [order.client_lat, order.client_lng];
    },
    isUserOnline(user) {
      var seconds = new Date(user.last_location_update).getTime() / 1000;
      var seconds_now = new Date();
      seconds_now = seconds_now.getTime() / 1000;
      if (seconds_now - seconds < 20) {
        //user is not online
        return false;
      }
      return true;
    },
  },
};
</script>
