<template>
  <v-card>
    <v-card-title class="align-start">
      <span>{{translate('Sales by Magazines')}}</span>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text>
      <v-list class="pb-0">
        <v-list-item
          v-for="(data, index) in salesByCountries"
          :key="data.country"
          :class="`d-flex align-center px-0 ${index > 0 ? 'mt-4' : ''}`"
        >
          <v-avatar
            :color="data.change > 0 ? 'success' : 'error'"
            size="40"
            :class="`${data.change > 0 ? 'success' : 'error'} white--text font-weight-medium me-3`"
          >
            <span class="text-base">{{ data.abbr }}</span>
          </v-avatar>

          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="me-2">
              <div class="font-weight-semibold">
                <span class="text--primary text-base me-1">{{ parseFloat(data.amount).toFixed(2) }}</span>

                <v-icon size="20" :color="data.change > 0 ? 'success' : 'error'">
                  {{ data.change > 0 ? icons.mdiChevronUp : icons.mdiChevronDown }}
                </v-icon>

                <span :class="`text-xs ${data.change > 0 ? 'success--text' : 'error--text'}`">{{ data.change }}%</span>
              </div>

              <v-list-item-subtitle class="text-xs">
                {{ data.country }}
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <div>
              <h4 class="font-weight-semibold">
                {{ data.sales }}
              </h4>
              <span class="text-xs">{{translate('Sales')}}</span>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from '@mdi/js'

export default {
  props: ['api_data', 'currency'],
  data() {
    return {
      salesByCountries: [],
    }
  },
  created: function () {
    if (this.api_data == null) return
    for (var i = 0; i < this.api_data.length; i++) {
      var magazine = this.api_data[i]

      var magazine_sale = {}
      magazine_sale = {
        abbr: magazine.name.substring(0, 2),
        country: magazine.name,
        sales: magazine.current_month.sales_amount,
        change: Math.round(magazine.change),
        amount: `${magazine.current_month.total_profit} ${this.currency}`,
      }
      this.salesByCountries.push(magazine_sale)
    }
  },
  setup() {
    return {
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>
