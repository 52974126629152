<template>
  <v-lazy>
    <div>
      <h2>Client</h2>
      <v-data-table dense :items-per-page="20" :headers="clientTableHeaders" :items="[order]" :hide-default-footer="true"
        item-key="name"></v-data-table>

      <br />
      <h2>{{ translate('Order Info') }}</h2>
      <v-divider></v-divider>
      <v-row dense>
        <v-col><span>{{ translate('Created at') }}</span>
          <span>
            <h3>{{ formatDate(order.order_created_at) }}</h3>
          </span></v-col>
        <v-col v-if="order.driver_picked_up_at != null"><span>{{ translate('Driver Picked up at') }}</span>
          <span>
            <h3>{{ formatDate(order.driver_picked_up_at) }}</h3>
          </span></v-col>
        <v-col v-if="order.driver_delivered_at != null"><span>{{ translate('Driver Delivered at') }}</span>
          <span>
            <h3>{{ formatDate(order.driver_delivered_at) }}</h3>
          </span></v-col>

        <v-col style="margin: 5px"><span>{{ translate('Status') }} </span>
          <span class="font-weight-medium v-chip v-size--small" :class="order.color"><span class="v-chip__content">
              {{ orderStatusToText(order.order_status) }}
            </span></span>

          <v-col class="pa-0"> </v-col>
        </v-col>
        <v-col v-if="order.order_status == 'returned'"><span>{{ translate('Return reason') }}</span>
          <span>
            <h3>{{ order.reason }}</h3>
          </span></v-col>
      </v-row>
      <v-divider></v-divider>
      <br />

      <v-row>
        <div v-for="image in images" :key="image.id">
          <div style="
              overflow: visible;
              position: relative;
              min-width: 110px;
              max-width: 110px;
              min-height: 110px;
              max-height: 110px;
              margin: 5px;
              padding: 5px;
              border-style: dashed;
            ">
            <a :href="image.image_url" target="_blank"><v-img v-if="isFileImage(image)" style="height: 95px; width: 100%"
                :src="image.image_url"></v-img>
              <span v-else style="
                  -webkit-line-clamp: 7;
                  display: -webkit-box;
                  line-height: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-break: anywhere;
                ">
                {{ image.filename }}</span>
            </a>
          </div>
        </div>
      </v-row>
      <br />
      <h3>{{ translate('Service Files') }} <a @click="openServiceImage">upload</a></h3>
      <v-data-table :items-per-page="20" dense :headers="[
        { text: this.translate('ID'), value: 'id' },
        { text: this.translate('File'), value: 'filename' },
        { text: this.translate('Actions'), value: 'actions' },
      ]" :items="serviceImages" :hide-default-footer="true" item-key="name">
        <template v-slot:item.filename="{ item }"><a :href="getDownloadServiceFileUrlLocal(item.filename)"
            target="_blank">{{ item.filename }}</a></template>
        <template v-slot:item.actions="{ item }">
          <v-icon @click.stop="deleteServiceFile(item.id)" small style="color: var(--v-error-base)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <br />
      <h3>{{ translate('Stocks') }}</h3>
      <v-data-table :items-per-page="20" dense :headers="stocksTableHeaders" :items="stocks" :hide-default-footer="true"
        item-key="name">
        <template v-slot:item.image="{ item }">
          <div style="
              overflow: visible;
              position: relative;
              min-width: 55px;
              max-width: 55px;
              min-height: 55px;
              max-height: 55px;
              margin: 5px;
              padding: 5px;
              border-style: dashed;
            ">
            <a :href="item.image_url" target="_blank"><v-img style="height: 45px; width: 100%"
                :src="item.image_url"></v-img></a>
          </div>
        </template>
        <template v-slot:item.amount="{ item }">{{ item.amount }} {{ item.measurement_name }}</template>
        <template v-slot:item.quantity_price="{ item }">{{ item.quantity_price }} {{ currency }}</template>
        <template v-slot:item.tax="{ item }">{{ item.tax }}%</template>
        <template v-slot:item.discount="{ item }">{{ item.discount }}%</template>
        <template v-slot:item.total="{ item }">{{ calculateTotal(item) }} {{ currency }}</template>
      </v-data-table>
      <h3 v-if="services.length != 0">{{ translate('Services') }}</h3>
      <v-data-table :items-per-page="20" dense v-if="services.length != 0" :headers="servicesTableHeaders"
        :items="services" :hide-default-footer="true" item-key="name">
        <template v-slot:item.price="{ item }">{{ item.price }} {{ currency }}</template>
        <template v-slot:item.total="{ item }">{{ item.price * item.amount }} {{ currency }}</template>
      </v-data-table>

      <div>
        <br />
        <h2>{{ translate('Sales Post') }}</h2>
        <v-divider></v-divider>
        <v-row style="margin-top: 5px">
          <v-col>
            <v-chip style="margin-top: 5px" :color="order.sales_post == null ? 'error' : 'primary'">
              {{ order.sales_post == null ? translate('Sales Post is not chosen') : '' }}
              {{ order.sales_post == 'courier' ? order.driver_name : textToSalesPost(order.sales_post) }}</v-chip>
          </v-col>
          <v-col>
            <v-select filled outlined :label="translate('Sales Post')" dense v-model="sales_post" :items="getDrivers"
              @input="salesPostChanged"></v-select>
          </v-col>
        </v-row>

        <v-data-table :items-per-page="20" v-if="order.driver_id != undefined" dense :headers="driverTableHeaders"
          :items="[order]" :hide-default-footer="true" item-key="name"></v-data-table>
      </div>
      <div>
        <br />
        <h2>{{ translate('Designers') }}</h2>
        <v-divider></v-divider>

        <v-data-table :items-per-page="20" v-if="designers.length != 0" dense :headers="designerTableHeaders"
          :items="designers" :hide-default-footer="true" item-key="name"></v-data-table>
      </div>
      <div>
        <br />
        <h2>{{ translate('Production') }}</h2>
        <v-divider></v-divider>

        <v-data-table :items-per-page="20" v-if="production.length != 0" dense :headers="designerTableHeaders"
          :items="production" :hide-default-footer="true" item-key="name"></v-data-table>
      </div>
      <br />
      <v-divider></v-divider>
      <br />
      <div class="text-left" v-if="order.order_status != 'completed' && isLocked!=true">
        <v-row justify="end" align-content="space-between">
          <v-col align-self="start">
            <v-row>
              <v-text-field style="padding-right: 5px; max-width:300px" v-model="returnReason"
                :label="translate('return reason')" clear-icon="shrink" outlined dense></v-text-field>
              <v-btn class="warning mr-20" :disabled="returnReason == null" @click="callReturn()">
                Return to sales</v-btn></v-row></v-col>
          <span style="color: red"> {{ order.error }}</span>
          <v-btn @click="returnToDesigner()" class="warning mr-5"> Return to Designer</v-btn>
          <v-btn-toggle v-if="showChooseDevposButton == true" elevation="0" divided variant="outlined">

            <v-btn color="success" @click="proceedDevpos(true)">Devpos</v-btn>
            <v-btn color="success" @click="proceedDevpos(false)">No Devpos</v-btn>
          </v-btn-toggle>
          <v-btn v-if="showChooseDevposButton == false" :disabled="order.sales_post == null ? true : false"
            @click="toggleStatus()" class="success">{{
              translate('proceed order')
            }}</v-btn>
        </v-row>
      </div>
      <input ref="serviceImageuploader" class="d-none" type="file" accept="" @change="uploadServiceImage" />

    </div>

  </v-lazy>
</template>
<script>
import { LMap, LTileLayer, LMarker, LTooltip, LPolyline, LIcon } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import { mdiGoogle } from '@mdi/js'

import axios from 'axios'
import {
  assignOrderToDriver,
  changeOrderStatus,
  getOrders,
  insertNewOrderImage,
  removeOrderImage,
  getUsers,
  moveOrder,
  getOrderInfo,
  proceedOrderFromProduction,
  getProductionOrders,
  changeOrderSalesPost,
  returnOrder,
  uploadServiceImage,
  getDownloadServiceFileUrl,
  deleteServiceImage,
} from '@/ApiManager'
import { devpos_chosen } from '@/ApiManager'
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  props: ['order', 'currency'],

  methods: {
    async proceedDevpos(devpos) {

      this.$store.state.loading = true;
      await devpos_chosen(this.order.id, devpos, { check_devpos: true })
      await moveOrder(0, this.order.order_status, this.order.id, this.order.sales_post)
      await moveOrder(0, this.order.order_status, this.order.id, this.order.sales_post)
      this.getNewData()
    },
    returnToDesigner() {
      this.goBack()
    },
    openServiceImage() {
      this.$refs.serviceImageuploader.click()
    },
    async uploadServiceImage(data) {
      this.$store.state.loading = true

      var file = data.target.files[0]
      await uploadServiceImage(file, this.order.id)
      await this.getNewData()
      this.$store.state.loading = false
    },
    getDownloadServiceFileUrlLocal(filename) {
      return getDownloadServiceFileUrl(this.order.id, filename)
    },
    async deleteServiceFile(id) {
      this.$store.state.loading = true

      await deleteServiceImage(id)
      await this.getNewData()

      this.$store.state.loading = false
    },
    isFileImage(file) {
      const fileType = file['filetype']
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']
      if (validImageTypes.includes(fileType)) {
        return true
      }
      return false
    },
    async callReturn() {
      this.$store.state.loading = true

      await returnOrder(this.order.id, this.returnReason)
      await this.getNewData()
      this.$store.state.loading = false
    },
    async salesPostChanged() {
      this.$store.state.loading = true
      await changeOrderSalesPost(this.order.id, this.sales_post)
      await this.getNewData()
      this.$store.state.loading = false
    },
    async toggleStatus() {
      if (this.order.sales_post == 'delivery_company') {
        this.$emit('openAskForDevposDataDialog', this.order)
        return
      }

      if (['client_picks_up'].includes(this.order.sales_post)) {
        this.showChooseDevposButton = true
        return;
      }

      this.$store.state.loading = true
      await proceedOrderFromProduction(this.order.id, this.order.sales_post)
      this.getNewData()
    },
    async goBack() {
      this.$store.state.loading = true

      await moveOrder(1, this.order.order_status, this.order.id, this.order.sales_post)
      await this.getNewData()

      this.$store.state.loading = false
    },
    async goFront() {
      this.$store.state.loading = true

      await moveOrder(0, this.order.order_status, this.order.id, this.order.sales_post)
      await this.getNewData()

      this.$store.state.loading = false
    },
    async removeOrderImage(image) {
      this.$store.state.loading = true

      await removeOrderImage(image.id)
      await this.getNewData()

      this.$store.state.loading = false
    },
    async openImagePrompt() {
      this.$refs.uploader.click()
    },
    async orderInfo() {
      var result = await getOrderInfo(this.order.id)
      this.production = result.production
      this.images = result.images
      this.designers = result.designers
      this.serviceImages = result.serviceImages

      this.stocks = result.stocks
      this.services = result.services
    },
    async uploadImage(data) {
      this.$store.state.loading = true

      var file = data.target.files[0]
      await insertNewOrderImage(file, this.order.id)
      this.$store.state.loading = false
      this.getNewData()
    },

    async getNewData() {
      var orders = null
      var response = await getProductionOrders(JSON.parse(localStorage.getItem('user')).id)
      orders = response.data
      orders.forEach(
        order => (
          (order.total_price = `${order.total_price} ${this.currency}`),
          (order.color = this.orderStatusToColor(order.order_status)),
          (order.order_status_formatted = this.orderStatusToText(order.order_status))
        ),
      )
      await this.orderInfo()
      this.$store.state.loading = false
      this.$emit('setNewData', orders)
    },
    async orderStatusChanged(orderStatus) {
      this.$store.state.loading = true

      var response = await changeOrderStatus(this.order.id, orderStatus)
      this.getNewData()
    },

    calculateTotal(stock) {
      var price = 0
      //get total price
      price = stock.amount * stock.quantity_price
      //calculate discount
      price = price - (price * stock.discount) / 100

      //calculate tax
      price = price + (price * stock.tax) / 100
      return price
    },
  },
  data() {
    return {

      askForDevposDataDialog: false,
      showChooseDevposButton: false,
      sales_post: null,
      returnReason: null,
      users: [],
      images: [],
      stocks: [],
      services: [],
      serviceImages: [],

      production: [],
      designers: [],

      file: null,
      orderStatuses: [
        { text: this.translate('Processing'), value: 'processing' },
        { text: this.translate('Returned'), value: 'returned' },
        { text: this.translate('Waiting for a Driver'), value: 'waiting_for_a_driver' },
        { text: this.translate('Client Picking Up'), value: 'client_picking_up' },
        { text: this.translate('Company Delivering'), value: 'company_delivering' },
        { text: this.translate('Driver Picking up'), value: 'driver_picking_up' },
        { text: this.translate('Driver Delivering'), value: 'driver_delivering' },
        { text: this.translate('Completed'), value: 'completed' },
      ],
      drivers: [],
      selected_driver: this.order.driver_name,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',

      zoom: 50,
      markerLatLng: [0, 0],
      leaflet_key: 0,
      clientTableHeaders: [
        { text: 'ID', value: 'client_id' },

        { text: this.translate('Name'), value: 'client_name' },
        { text: this.translate('Phone Number'), value: 'client_phone' },
        { text: this.translate('Address'), value: 'client_address' },
      ],
      driverTableHeaders: [
        { text: 'ID', value: 'driver_id' },
        { text: this.translate('Name'), value: 'driver_name' },
        { text: this.translate('Phone Number'), value: 'driver_phone_number' },
      ],
      designerTableHeaders: [
        { text: 'ID', value: 'id' },
        { text: this.translate('Name'), value: 'name' },
        { text: this.translate('Phone Number'), value: 'phone_number' },
        { text: this.translate('Status'), value: 'status' },
      ],
      stocksTableHeaders: [
        { text: this.translate('Image'), value: 'image' },

        { text: this.translate('Product'), value: 'product_name' },
        { text: this.translate("Category"), value: "category_name" },
        { text: this.translate("Machine"), value: "machine_name" },

        { text: this.translate('Quantity Price'), value: 'quantity_price' },
        { text: this.translate('Amount'), value: 'amount' },
        { text: this.translate('Discount'), value: 'discount' },
        { text: this.translate('Tax'), value: 'tax' },
        { text: this.translate('Total'), value: 'total' },
      ],
      servicesTableHeaders: [
        { text: this.translate('Name'), value: 'name' },
        { text: this.translate('Text'), value: 'text' },
        { text: this.translate('Notes'), value: 'notes' },

        { text: this.translate('Price'), value: 'price' },
        { text: this.translate('Amount'), value: 'amount' },
        { text: this.translate('Total'), value: 'total' },
      ],
    }
  },
  computed: {
    isLocked(){
     return this.order.order_status!='production'
    },
    showBack() {
      return this.order.order_status != 'processing'
    },
    showFront() {
      return this.order.order_status != 'completed' || this.order.order_status != 'refused'
    },
    getDrivers() {
      var drivers = []
      drivers.push({ value: 'client_picks_up', text: 'Shop Peshkopi' })
      drivers.push({ value: 'delivery_company', text: 'Addex' })
      for (var i = 0; i < this.users.length; i++) {
        console.log(this.users[i])
        if (this.users[i].role == 'driver') drivers.push({ value: this.users[i].id, text: this.users[i].name })
      }

      return drivers
    },

    dynamicSize() {
      return [this.iconSize * 0.5, this.iconSize * 0.5]
    },
    dynamicAnchor() {
      return [this.iconSize * 0.5, this.iconSize * 0.5]
    },
    getSubTotal() {
      var price = 0
      for (var i = 0; i < this.stocks.length; i++) {
        price += this.stocks[i].quantity_price * this.stocks[i].amount
      }
      return price
    },
    getServiceTotal() {
      var service = 0
      for (var i = 0; i < this.services.length; i++) {
        service += this.services[i].price * this.services[i].amount
      }
      return service
    },
    getProfit() {
      //first calculate the buy price of each order INCLUDING taxes
      var profit = 0
      for (var i = 0; i < this.stocks.length; i++) {
        var stock = this.stocks[i]
        var order_buy_price = stock.order_buy_price + stock.order_buy_price * 0.2
        var buy_cost_total = order_buy_price * stock.amount
        var price = 0
        //get total price
        price = stock.amount * stock.quantity_price
        //calculate discount
        price = price - (price * stock.discount) / 100
        profit += price - buy_cost_total
      }

      return profit + this.getServiceTotal
    },
    getDiscount() {
      var price = 0
      for (var i = 0; i < this.stocks.length; i++) {
        var cost = this.stocks[i].quantity_price * this.stocks[i].amount
        price += (cost * this.stocks[i].discount) / 100
      }
      return price
    },
    getTax() {
      var price = 0
      for (var i = 0; i < this.stocks.length; i++) {
        var cost = this.stocks[i].quantity_price * this.stocks[i].amount
        cost -= (cost * this.stocks[i].discount) / 100
        price += (cost * this.stocks[i].tax) / 100
      }
      return price
    },
    get_delivery_fee() {
      return this.delivery_fee
    },
    getGrandTotal() {
      var price = 0
      for (var i = 0; i < this.stocks.length; i++) {
        var stock = this.stocks[i]
        //get total price
        var cost = stock.amount * stock.quantity_price
        //calculate discount
        if (stock.discount != 0) {
          cost = cost - (cost * stock.discount) / 100
        }
        //calculate tax
        if (stock.tax != 0) {
          cost = cost + (cost * stock.tax) / 100
        }
        price += cost
      }
      return parseInt(price) + parseInt(this.order.delivery_fee) + this.getServiceTotal
    },
  },
  components: {
    LMap,
    mdiGoogle,
    LTileLayer,
    LMarker,
    LTooltip,
    LPolyline,
    LIcon,
  },
  async created() {
    this.$store.state.loading = true
    this.users = await getUsers()

    await this.orderInfo()
    this.$store.state.loading = false
  },
}
</script>
<style scoped>
.customBtn {
  height: 25px;
  width: 25px;
}

.v-input {
  font-size: 0.8rem;
}

.v-input .v-label {
  font-size: 1rem;
}
</style>
